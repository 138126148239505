import mock from '@/@fake-db/mock'
/* eslint-disable global-require */
const data = {
  tableBasic: [
    {
      id: 1,
      example1: 'Lorem ipsum dolor',
      example2: '',
      example3: 'EUR',
      example4: 'Completed',
      example5: '',
      example6: '',
    },
    {
      id: 2,
      example1: "Lorem ipsum dolor",
      example2: '',
      example3: 'USD',
      example4: 'In progress',
      example5: '',
      example6: '',
    },
    {
      id: 3,
      example1: "Lorem ipsum dolor",
      example2: '',
      example3: 'EUR',
      example4: 'Completed',
      example5: '',
      example6: '',
    },
  ],

  tableSimulation: [
    {
      id: 1,
      example1: 'Simulação 01',
      example2: 'Curabitur lobortis neque diam',
      example3: 'EUR',
      example4: 'Completed',
      example5: 'Ricardo Neto',
      example6: '2021-07-23',
    },
    {
      id: 2,
      example1: "Simulação 02",
      example2: 'Curabitur lobortis neque diam',
      example3: 'USD',
      example4: 'In progress',
      example5: 'Ricardo Neto',
      example6: '2021-07-23',
    },
    {
      id: 3,
      example1: 'Simulação 03',
      example2: 'Curabitur lobortis neque diam',
      example3: 'EUR',
      example4: 'Completed',
      example5: 'Ricardo Neto',
      example6: '2021-07-23',
    },
    {
      id: 4,
      example1: 'Simulação 04',
      example2: 'Curabitur lobortis neque diam',
      example3: 'EUR',
      example4: 'In progress',
      example5: 'Ricardo Neto',
      example6: '2021-07-23',
    },
    {
      id: 5,
      example1: "Simulação 05",
      example2: 'Curabitur lobortis neque diam',
      example3: 'USD',
      example4: 'Error',
      example5: 'Ricardo Neto',
      example6: '2021-07-23',
    },
    {
      id: 6,
      example1: 'Simulação 06',
      example2: 'Curabitur lobortis neque diam',
      example3: 'EUR',
      example4: 'Completed',
      example5: 'Ricardo Neto',
      example6: '2021-07-23',
    },
  ],

  tableSimulation2: [
    {
      id: 1,
      example1: 'Lorem ipsum dolor',
      example2: '',
      example3: 'EUR',
      example4: 'Completed',
      example5: '',
      example6: '',
    },
    {
      id: 2,
      example1: "Lorem ipsum dolor",
      example2: '',
      example3: 'USD',
      example4: 'In progress',
      example5: '',
      example6: '',
    },
    {
      id: 3,
      example1: "Lorem ipsum dolor",
      example2: '',
      example3: 'EUR',
      example4: 'Completed',
      example5: '',
      example6: '',
    },
  ],
  tableSimulation3: [
    {
      id: 1,
      avatar: '',
      example1: 'Lorem ipsum dolor',
      example2: '',
      example3: 'EUR',
      example4: 'Completed',
      example5: '',
      example6: '',
    },
    {
      id: 2,
      avatar: '',
      example1: "Lorem ipsum dolor",
      example2: '',
      example3: 'USD',
      example4: 'In progress',
      example5: '',
      example6: '',
    },
    {
      id: 3,
      avatar: '',
      example1: "Lorem ipsum dolor",
      example2: '',
      example3: 'EUR',
      example4: 'Completed',
      example5: '',
      example6: '',
    },
  ],
  tableStructure: [
    {
      id: 1,
      avatar: '',
      example1: 'Lorem ipsum dolor',
      example2: '',
      example3: 'EUR',
      example4: 'Completed',
      example5: '',
      example6: '',
    },
    {
      id: 2,
      avatar: '',
      example1: "Lorem ipsum dolor",
      example2: '',
      example3: 'USD',
      example4: 'In progress',
      example5: '',
      example6: '',
    },
    {
      id: 3,
      avatar: '',
      example1: "Lorem ipsum dolor",
      example2: '',
      example3: 'EUR',
      example4: 'Completed',
      example5: '',
      example6: '',
    },
  ],
  tableUpload: [
    {
      id: 1,
      example1: 'Simulação 01',
      example2: 'Curabitur lobortis neque diam',
      example3: 'EUR',
      example4: 'Curabitur lobortis neque diam',
      example5: 'Ricardo Neto',
      example6: '2021-07-23',
    },
    {
      id: 2,
      example1: "Simulação 02",
      example2: 'Curabitur lobortis neque diam',
      example3: 'USD',
      example4: 'Curabitur lobortis neque diam',
      example5: 'Ricardo Neto',
      example6: '2021-07-23',
    },
    {
      id: 3,
      example1: 'Simulação 03',
      example2: 'Curabitur lobortis neque diam',
      example3: 'EUR',
      example4: 'Curabitur lobortis neque diam',
      example5: 'Ricardo Neto',
      example6: '2021-07-23',
    },
  ],
}

mock.onGet('/good-table/basic').reply(() => [200, data.tableBasic])
mock.onGet('/good-table/simulation').reply(() => [200, data.tableSimulation])
mock.onGet('/good-table/simulation2').reply(() => [200, data.tableSimulation2])
mock.onGet('/good-table/simulation3').reply(() => [200, data.tableSimulation3])
mock.onGet('/good-table/structure').reply(() => [200, data.tableStructure])
mock.onGet('/good-table/upload').reply(() => [200, data.tableUpload])
