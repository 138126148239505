<template>
  <footer class="footer-pages">
      <b-col
        class="footer-logo desktop-footer"
        offset-xl="1"
        xl="3"
        lg="3"
      >
        <b-img
          fluid
          :src="require('@/assets/images/logo/footer-logo.png')"
          alt="footer-logo"
        />
      </b-col>
      <b-col
        class="footer-menu desktop-footer"
        xl="4"
        lg="5"
      >
        <ul>
          <li>
            <b-link :to="{path:'/home'}">
              Home
            </b-link>
          </li>
          <li>
            <b-link :to="{path:'/#'}">
              About Us
            </b-link>
          </li>
          <li>
            <b-link :to="{path:'/#'}">
              Contacts
            </b-link>
          </li>
          <li>
            <b-link :to="{path:'/#'}">
              Need Help?
            </b-link>
          </li>
        </ul>
      </b-col>
      <b-col
        class="footer-button p-0 desktop-footer"
        xl="3"
        lg="4"
      >
          <b-button
            class="email-footer-btn"
            variant="none"
          >
            <feather-icon
              icon="MessageSquareIcon"
            />
            costctrlsupport@email.com
          </b-button>
      </b-col>
      <b-row class="m-0 d-lg-none">
        <b-col
          class="footer-logo-mobile"
          md="6"
          cols="12"
        >
          <b-img
            fluid
            :src="require('@/assets/images/logo/footer-logo.png')"
            alt="footer-logo"
          />
        </b-col>
        <b-col
          class="footer-button-mobile"
          md="6"
          cols="12"
        >
            <b-button
              class="email-footer-btn"
              variant="none"
            >
              <feather-icon
                icon="MessageSquareIcon"
              />
              costctrlsupport@email.com
            </b-button>
        </b-col>
      </b-row>
  </footer>
</template>

<script>
import { BRow, BCol, BLink, BButton, BImg } from 'bootstrap-vue'

export default {
  name: 'AppFotter',
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    BImg
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-footer.scss';
</style>