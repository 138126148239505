export default [
  {
    path: '/software/step01',
    name: 'software-step01',
    component: () => import('@/views/software/softwareStep01.vue'),
  },
  {
    path: '/software/step02',
    name: 'software-step02',
    component: () => import('@/views/software/softwareStep02.vue'),
  },
  {
    path: '/software/step03',
    name: 'software-step03',
    component: () => import('@/views/software/step03/softwareStep03.vue'),
  },
  {
    path: '/software/step04',
    name: 'software-step04',
    component: () => import('@/views/software/step04/softwareStep04.vue'),
  },
  {
    path: '/software/docStructure',
    name: 'software-structure',
    component: () => import('@/views/software/docStructure/softwareDocStructure.vue'),
  },
  {
    path: '/software/dimension/list',
    name: 'software-dimension-list',
    component: () => import('@/views/software/dimension/list/dimensionList.vue'),
  },
  {
    path: '/software/dimension/edit',
    name: 'software-dimension-edit',
    component: () => import('@/views/software/dimension/edit/dimensionEdit.vue'),
  },
  {
    path: '/software/dimension/upload',
    name: 'software-upload-complete',
    component: () => import('@/views/software/dimension/edit/dimensionUploadComplete.vue'),
  },
]
