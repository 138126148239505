<template>
  <header class="header-pages">
        <b-col
          class="header-logo destkop-header"
          lg="3"
        >
          <b-link :to="{path:'/home'}">
            <cost-logo />
          </b-link>
        </b-col>
        <b-col
          class="header-menu destkop-header"
          lg="6"
        >
          <ul>
            <li>
              <b-link :to="{path:'/home'}">
                Home
              </b-link>
            </li>
            <li>
              <b-link :to="{path:'/about-us'}">
                About Us
              </b-link>
            </li>
            <li>
              <b-link :to="{path:'/contact'}">
                Contacts
              </b-link>
            </li>
            <li>
              <b-link :to="{path:'/need-help'}">
                Need Help?
              </b-link>
            </li>
          </ul>
        </b-col>
        <b-col
          class="header-button destkop-header"
          lg="3"
        >
            <b-button
              class="sign-btn"
              variant="none"
              :to="{path:'/register'}"
            >
              Sign up
            </b-button>
            <b-button
              class="login-btn"
              :to="{path:'/login'}"
            >
              <feather-icon
                icon="UserIcon"
              />
              Login
            </b-button>
        </b-col>

      <b-row class="m-0 d-lg-none header-mobile">
        <b-col
          class="header-logo-mobile"
          md="6"
          cols="4"
        >
          <b-link :to="{path:'/home'}">
            <cost-logo />
          </b-link>
        </b-col>
        <b-col
          class="header-menu-mobile p-0"
          md="6"
          cols="8"
        >
          <a href="javascript:void(0)" class="btn-menu" @click="toggleMenu">
            <span class="btn-open" :class="{show : menuOpen}">
              <feather-icon
                  icon="MenuIcon"
                />
            </span>
            <span class="btn-close" :class="{show : menuOpen}">
              <feather-icon
                  icon="XIcon"
                />
            </span>
          </a>
          <div class="nav-menu" :class="{open : menuOpen}">
            <ul class="mobile-menu">
              <li>
                <b-link :to="{path:'/home'}" @click="toggleMenu">
                  Home
                </b-link>
              </li>
              <li>
                <b-link :to="{path:'/about-us'}" @click="toggleMenu">
                  About Us
                </b-link>
              </li>
              <li>
                <b-link :to="{path:'/contact'}" @click="toggleMenu">
                  Contacts
                </b-link>
              </li>
              <li>
                <b-link :to="{path:'/need-help'}" @click="toggleMenu">
                  Need Help?
                </b-link>
              </li>
              <li>
                <b-link :to="{path:'/register'}" @click="toggleMenu">
                  Sign up
                </b-link>
              </li>
              <li>
                <b-link :to="{path:'/login'}" @click="toggleMenu">
                  Login
                </b-link>
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
  </header>
</template>

<script>
import $ from "jquery";
import { BRow, BCol, BLink, BButton } from 'bootstrap-vue'
import CostLogo from '@core/layouts/components/Logo.vue'

export default {
  name: 'AppHeader',
  components: {
    BRow,
    BCol,
    BLink,
    BButton,
    CostLogo
  },
  data() {
    return {
      menuOpen: false,
    }
  },
  methods: {
    toggleMenu() {
      if(this.menuOpen==true){
        setTimeout(()=>{
          this.menuOpen=false;
        });
      }
      else{
        this.menuOpen = true;
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-header.scss';
</style>